var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('h2',{staticClass:"title"},[_vm._v("学习进度")]),_c('el-row',{staticClass:"learn-process",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"learn-item"},[_c('p',[_vm._v(" 已学习学时: "),_c('span',[_vm._v(_vm._s(parseInt(this.daily_duration / 60))+"分钟")]),(this.rule.is_showvalidperiod == 1)?_c('a',[_vm._v(" 有效学时: "),_c('span',[_vm._v(_vm._s(parseInt(this.period_hours / 60))+"分钟")])]):_vm._e()]),_c('el-progress',{attrs:{"percentage":_vm.finish_per,"color":_vm.customColor}}),_c('div',{staticClass:"log-list-box"},[_c('div',{staticClass:"log-title"},[_c('span',[_vm._v("电子教学日志")])]),_c('div',{staticClass:"log-list"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.newData}},[_c('el-table-column',{attrs:{"prop":"starttime","label":"日期","width":"310"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.starttime)+" — "+_vm._s(scope.row.endtime)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"duration","label":"学时","width":"100"}}),_c('el-table-column',{attrs:{"prop":"newstage","label":"状态","filters":[
                  { text: '无效', value: '无效' },
                  { text: '未同步', value: '未同步' },
                  // { text: '审核中', value: '审核中' },
                  { text: '失效', value: '失效' },
                  { text: '初审', value: '初审' },
                  { text: '复审', value: '复审' },
                  { text: '已同步', value: '已同步' }
                ],"filter-method":_vm.filterTag},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":scope.row.newstage === '已同步' ? 'success' : 'primary',"disable-transitions":""}},[_vm._v(_vm._s(scope.row.newstage))])]}}])})],1)],1),_c('a',{staticClass:"continue-learn",on:{"click":function($event){return _vm.goto()}}},[_vm._v("继续学习")])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }