<template>
  <el-main>
    <h2 class="title">学习进度</h2>
    <el-row :gutter="20" class="learn-process">
      <el-col :span="12">
        <div class="learn-item">
          <p>
            已学习学时:
            <span>{{ parseInt(this.daily_duration / 60) }}分钟</span>
            <a v-if="this.rule.is_showvalidperiod == 1">
              有效学时:
              <span>{{ parseInt(this.period_hours / 60) }}分钟</span>
            </a>
          </p>
          <el-progress
            :percentage="finish_per"
            :color="customColor"
          ></el-progress>
          <div class="log-list-box">
            <div class="log-title"><span>电子教学日志</span></div>
            <div class="log-list">
              <el-table :data="newData" style="width: 100%">
                <el-table-column prop="starttime" label="日期" width="310">
                  <template slot-scope="scope">
                    {{ scope.row.starttime }}
                    &nbsp;—&nbsp;
                    {{ scope.row.endtime }}
                  </template>
                </el-table-column>
                <el-table-column prop="duration" label="学时" width="100">
                </el-table-column>
                <el-table-column
                  prop="newstage"
                  label="状态"
                  :filters="[
                    { text: '无效', value: '无效' },
                    { text: '未同步', value: '未同步' },
                    // { text: '审核中', value: '审核中' },
                    { text: '失效', value: '失效' },
                    { text: '初审', value: '初审' },
                    { text: '复审', value: '复审' },
                    { text: '已同步', value: '已同步' }
                  ]"
                  :filter-method="filterTag"
                >
                  <template slot-scope="scope">
                    <el-tag
                      :type="
                        scope.row.newstage === '已同步' ? 'success' : 'primary'
                      "
                      disable-transitions
                      >{{ scope.row.newstage }}</el-tag
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <a @click="goto()" class="continue-learn">继续学习</a>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>
<script>
import { getDaily, getprogress } from '../api/daily'
import { courserule } from '../api/courses'
import {
  getIsPay,
  getResult,
  getSubjectId,
  getTerm,
  removeResult
} from '../utils/auth'

export default {
  data() {
    return {
      rule: [],
      finish_per: 0,
      period_hours: 0,
      finish_duration: 0,
      customColor: '#f99b08',
      tableData: [],
      newData: [],
      daily_duration: 0
    }
  },
  components: {},
  created() {
    if (getResult() === undefined) {
      this.$alert('登录过期，请登录后再访问', '登录提示', {
        confirmButtonText: '重新登录',
        callback: action => {
          removeResult()
          location.href = '#/login'
        }
      })
    } else {
      this.province = JSON.parse(getResult()).province_id
      this.subject_id = parseInt(getSubjectId())
      this.getDaily()
      this.getProgress()
      this.getrule()
    }
  },
  methods: {
    getrule() {
      let params = {
        licence_id: JSON.parse(getResult()).licence_id,
        term: getTerm()
      }
      courserule(params).then(res => {
        if (res.code === 0) {
          this.rule = res.result
        }
      })
    },
    getProgress() {
      let params = {
        licence_id: JSON.parse(getResult()).licence_id,
        term: getTerm()
      }
      getprogress(params).then(res => {
        if (res.code === 0) {
          //有效学时
          for (
            let i = 0;
            i < res.result.duration.daily_validate_duration.length;
            i++
          ) {
            if (
              this.subject_id ==
              res.result.duration.daily_validate_duration[i].subject
            ) {
              this.period_hours =
                res.result.duration.daily_validate_duration[i].duration
            }
          }
          //已学学时
          for (
            let i = 0;
            i < res.result.duration.daily_study_duration.length;
            i++
          ) {
            if (
              this.subject_id ==
              res.result.duration.daily_study_duration[i].subject
            ) {
              this.daily_duration =
                res.result.duration.daily_study_duration[i].duration
            }
          }
          //进度条
          for (let i = 0; i < res.result.duration.finish_duration.length; i++) {
            if (
              this.subject_id == res.result.duration.finish_duration[i].subject
            ) {
              this.finish_duration =
                res.result.duration.finish_duration[i].duration
            }
            if (
              this.subject_id == res.result.duration.subject_duration[i].subject
            ) {
              this.subject_duration =
                res.result.duration.subject_duration[i].duration
            }
          }
          if (
            parseInt((this.finish_duration / this.subject_duration) * 100) >=
            100
          ) {
            this.finish_per = 100
          } else {
            this.finish_per = parseInt(
              (this.finish_duration / this.subject_duration) * 100
            )
          }
          if (isNaN(this.finish_per)) {
            this.finish_per = 0
          }
        }
      })
    },
    getDaily() {
      getDaily({
        subject_id: this.subject_id,
        term: getTerm()
      }).then(res => {
        if (res.code === 0) {
          this.tableData = res.result
          this.newData = res.result
          for (let i = 0; i < this.tableData.length; i++) {
            // if (this.tableData[i].stage === 3) {
            //   this.period_hours = this.period_hours + this.tableData[i].duration
            // }
            let result = parseInt(this.newData[i].duration)
            let h =
              Math.floor(result / 3600) < 10
                ? '0' + Math.floor(result / 3600)
                : Math.floor(result / 3600)
            let m =
              Math.floor((result / 60) % 60) < 10
                ? '0' + Math.floor((result / 60) % 60)
                : Math.floor((result / 60) % 60)
            let s =
              Math.floor(result % 60) < 10
                ? '0' + Math.floor(result % 60)
                : Math.floor(result % 60)

            let res = ''
            if (h !== '00') {
              res += `${h}:`
            }
            if (m !== '00') {
              res += `${m}:`
            } else {
              res += `00:`
            }
            if (s !== '00') {
              res += `${s}`
            } else {
              res += `00`
            }
            this.newData[i].duration = res
            this.newData[i].newstage = ''

            if (this.tableData[i].stage === -1) {
              this.newData[i].newstage = '无效'
            } else if (this.tableData[i].stage === 0) {
              this.newData[i].newstage = '未同步'
            } else if (this.tableData[i].stage === 1) {
              this.newData[i].newstage = '初审'
            } else if (this.tableData[i].stage === 2) {
              this.newData[i].newstage = '复审'
            } else if (this.tableData[i].stage === 3) {
              if (this.tableData[i].status === -1) {
                this.newData[i].newstage = '失效'
              }
              if (this.tableData[i].status === 0) {
                this.newData[i].newstage = '未同步'
              }
              if (this.tableData[i].status === 1) {
                this.newData[i].newstage = '已同步'
              }
            }

            // if (this.tableData[i].stage === -1) {
            //   this.newData[i].newstage = '无效'
            // } else if (this.tableData[i].stage === 0) {
            //   this.newData[i].newstage = '未同步'
            // } else if (
            //   this.tableData[i].stage === 1 &&
            //   this.province === 440000
            // ) {
            //   this.newData[i].newstage = '审核中'
            // } else if (
            //   this.tableData[i].stage === 1 &&
            //   this.province !== 440000
            // ) {
            //   this.newData[i].newstage = '初审'
            // } else if (
            //   this.tableData[i].stage === 2 &&
            //   this.province === 440000
            // ) {
            //   this.newData[i].newstage = '审核中'
            // } else if (
            //   this.tableData[i].stage === 2 &&
            //   this.province !== 440000
            // ) {
            //   this.newData[i].newstage = '复审'
            // } else if (this.tableData[i].stage === 3) {
            //   this.newData[i].newstage = '已同步'
            // }
          }
        }
      })
    },
    filterTag(value, row) {
      return row.newstage === value
    },
    goto() {
      //if (JSON.parse(getResult()).is_pay >= 2) {
      if (getIsPay() >= 2) {
        this.$router.push({ path: '/catalogs' })
      } else {
        //this.$router.push({ path: '/catalogs/course' })
        this.$router.push({
          path:
            '/catalogs/shikan?licence_id=' + JSON.parse(getResult()).licence_id
        })
      }
    }
  }
}
</script>
